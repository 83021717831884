import Api from '@/services/Index';

const checkQrString = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/ticket/event/application/ticket-information', formData);
}

const confirmTicket = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/ticket/event/application/scan-ticket', formData);
}

const startApplication = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/ticket/event/application/start-application', data);
}

const emailVerify = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;

    return Api.post('/ticket/event/application/verify-application', data,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

const priceCalculator = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/ticket/event/application/price-calculator', data);
}

const usePromotionCode = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/ticket/event/application/apply-promotion-code', data);
}

const payment = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/ticket/event/application/make-payment', data);
}

const getTicketEventTypesByCode = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/ticket/event/application/get-ticket-event-types-by-code/' + code);
}

const getPaymentResponse = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/ticket/event/application/get-status', data);
}

const ticketDownload = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/ticket/event/application/download-ticket',data, {responseType: 'arraybuffer'});

}

const ticketBulkApprove = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/ticket/event/application/bulk-approve',data);

}

export default {
    checkQrString,
    confirmTicket,
    startApplication,
    emailVerify,
    priceCalculator,
    usePromotionCode,
    payment,
    getTicketEventTypesByCode,
    getPaymentResponse,
    ticketDownload,
    ticketBulkApprove
}
